export default {
	/* 表格数据 */
	table:[
		{
			id: 1,
			field1: '0050 总监儿童剪发10次卡',
			field2: 90,
			field3: 1,
			field4: [
				{ id: 1 , name: '张三'},
				{ id: 2 , name: '李四'}
			],
			field5: [
				{ id: 1 , name: '小心机' , name2: '非指定' ,  name3: 0 , name4: 0 },
				{ id: 2 , name: '小可爱' , name2: '指定' ,  name3: 0 , name4: 0 }
			],
			field6: [
				{ 
					id: 1 , 
					field1: '发型师' , 
					field2: ''/* [
						{ id: 1 , name: '张三'},
						{ id: 2 , name: '李四'}
					] */ ,  
					field3: 0,
					field4: 0,
					field5: 0
				}, 
				{
					id: 2 , 
					field1: '洗护师' , 
					field2: '' /* [
						{ id: 1 , name: '张三'},
						{ id: 2 , name: '李四'}
					] */ ,  
					field3: 0,
					field4: 0,
					field5: 0
				}, 
			]
		},
		{
			id: 2,
			field1: '10 高级烫发',
			field2: 90,
			field3: 1,
			field4: [
				{ id: 1 , name: '张三'},
				{ id: 2 , name: '李四'}
			],
			field5: [
				{ id: 1 , name: '小心机' , name2: '非指定' ,  name3: 0 , name4: 0 },
				{ id: 2 , name: '小可爱' , name2: '指定' ,  name3: 0 , name4: 0 }
			],
			field6: [
				{ 
					id: 1 , 
					field1: '美容师' , 
					field2: 'helloworld' ,  
					field3: 0,
					field4: 0,
					field5: 0
				}, 
				{
					id: 2 , 
					field1: '美容师' , 
					field2: '' ,  // []
					field3: 0,
					field4: 0,
					field5: 0
				}, 
			]
		}
	]
}