<template>
	<div class="bill">
		<div class="input">
			<el-popover
				placement="bottom-start"
				width="600"
				trigger="click"
				:visible-arrow="false">
				<div class="focus">
					 <el-container>
						 <el-header height="40px">
							 <span 
							 v-for="(item,index) in yiDate" 
							 :key="index"
							 :class="allKeyCode.isOneAction == index ? 'action' : ''"
							 :style="(allKeyCode.oneSubscript == index && allKeyCode.agent == 0) ? 'border: 1px solid red;' : '' ">
							    {{ item.title }}
							 </span>
						 </el-header>
						 <el-container>
							 <el-aside width="120px" height="360px">
								 <ul>
									<li 
									 v-for="(item,index) in erData" 
									 :key="index" 
									 :style=" (allKeyCode.twoSubscript == index && allKeyCode.agent == 1) ? 'border: 1px solid red; color:red;' : ''"
									 :class=" allKeyCode.isTwoAction == index ? 'isAction' : ''">
									  {{item.title}}
									</li>
								 </ul>
							 </el-aside>
							 <el-main>
								  <div ref="elMain" style="width: 100%; height: 350px; overflow-y: scroll;">
										 <div v-for="(item,index) in itemData" :key="index">
											 <div class="title">{{item.title}}</div>
											 <ul>
												 <li 
												 v-for="(itemChild,indexChild) in item.itemData"
												 :key="indexChild" :style=" (itemChild.isBox && allKeyCode.threeNestedSubscript === index) ? 'border: 1px solid red' : '' ">
													 <span>{{itemChild.number}}</span>
													 <span>{{itemChild.name}}</span>
												 </li>
											 </ul>
										 </div>
								  </div>
							 </el-main>
						 </el-container>
					 </el-container>
				</div>
				<div slot="reference" class="all">
					<i class="el-icon-search"></i>
					<el-input ref="addItem" @keyup.enter.native="enterAddItem($event)"   v-model="input" placeholder="请输入内容"></el-input>
				</div>
			</el-popover>
		</div>
		<!-- table -->
		<div class="table">
			<table>
				<tr>
					<th>项目</th>
					<th>原价</th>
					<th>数量</th>
					<th>疗程/赠金/优惠/积分</th>
					<th>员工</th>
					<th>操作</th>
				</tr>
			  <tr v-for="(item,index) in itemObj.tableData" :key="index">
			    <td>{{item.field1}}</td>
			    <td class="input">
						<el-input 
						:ref="'price_'+index" 
						@keyup.enter.native="enterPrice($event,index)"  
						v-model="item.field2" 
						size="small">
						</el-input>
					</td>
					<td class="number">
						<el-input-number 
						 v-model="item.field3"
						 @change="handleChange" 
						 :min="1" :max="10"  
						 size="small"
						 :ref="'number_'+index"
						 @keyup.enter.native="enterNumber($event,index)">
						</el-input-number>
					</td>
					<td class="select">
						<el-select 
						:ref="'integral_'+index" 
						v-model="item.integral" 
						@keyup.enter.native="enterIntegral($event,index)"
						size="small">
							<el-option
								v-for="(itemChild, indexChild) in item.field4"
								:key="indexChild"
								:label="itemChild.name"
								:value="item.id">
							</el-option>
						</el-select>
					</td>
					<td class="employee">
						<!-- 二级菜单 -->
						<el-popover
							placement="bottom"
							width="900"
							trigger="click"
							:ref="'popover_'+index"
							@show="showPopover(index)">
							<div class="content">
								<div class="title">
									<span>设置员工业绩和提成</span>
									<i class="el-icon-close"></i>
								</div>
								<div>
									<table>
										<thead>
											<tr>
												<th>轮牌</th>
												<th>员工</th>
												<th>指定</th>
												<th>业绩</th>
												<th>提成</th>
												<th>操作</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(itemChild,indexChild) in item.field6" :key="indexChild">
												<td class="type">
													<p>{{itemChild.field1}}</p>
													<p>{{itemChild.field1}}</p>
												</td>
												<td class="employee">
													<el-popover
														placement="bottom-start"
														width="500"
														trigger="click">
														<div>
															llll
														</div>
													  <el-input 
															:ref="'employee_'+ indexChild" 
															@keyup.enter.native="enterEmployee($event, indexChild)" 
															v-model="itemChild.field2" size="small"
															slot="reference"
															v-focus>
													  </el-input>
													</el-popover>
												</td>
												<td class="input">
													<el-input 
														:ref="'assign_'+ indexChild" 
														@keyup.enter.native="enterAssign($event, indexChild)" 
														v-model="itemChild.field3" 
														size="small"
														maxlength="1">
													</el-input>
													<el-radio-group v-model="itemChild.field3" size="small">
														<el-radio-button :label="1">指定</el-radio-button>
														<el-radio-button :label="0">非指定</el-radio-button>
													</el-radio-group>
												</td>
												<td class="public">
													<el-input
														v-model="itemChild.field4"
														size="small"
														@keyup.enter.native="enterPerfor($event, indexChild)"
														:ref="'perfor_'+ indexChild">
														<span slot="suffix">元</span>
													</el-input>
												</td>
												<td class="public">
												  <el-input
														v-model="itemChild.field5"
														size="small"
														@keyup.enter.native="enterRotay($event, indexChild , index)"
														:ref="'rotay_'+ indexChild">
														<span slot="suffix">元</span>
													</el-input>
												</td>
												<td class="close">清空</td>
											</tr>
										</tbody>
									</table>
									<div class="add">
										<div :style=" itemObj.addEmployee ? 'border: 1px solid #2597ff;' : ''">
											<i class="el-icon-circle-plus-outline"></i>
											<span>添加员工</span>
										</div>
									</div>
									<div class="bottom">
										<span>* 1 为指定，0 为非指定</span>
										<span>
											<el-checkbox v-model="checked">勾选后，业绩提成将不再自动计算</el-checkbox>
										</span>
										<span>
											点击重算
											<i class="el-icon-s-opportunity"></i>
										</span>
										<span>
											 <el-button type="primary" size="mini">完成（*）</el-button>
										</span>
									</div>
								</div>
							</div>
							<div slot="reference">
								<p v-for="(itemChild, indexChild) in item.field5" :key="indexChild">
									<span>{{itemChild.id}}</span>
									<span>{{itemChild.name}}</span>
									<span>{{itemChild.name2}}</span>
									<span>(业{{itemChild.name3}}，提{{itemChild.name4}})</span>
								</p>
							</div>
						</el-popover>
					</td>
					<td class="edit">
						<i class="el-icon-delete"></i>
					</td>
			  </tr>
			</table><!-- end table -->
		</div>
	</div>
</template>

<script>
	import obj from '../table.js'
	import itemData from '../item.js'
	export default {
		data() {
			return {
				itemObj: {
						tableData: obj.table, //表格数据
						integral: '', // 积分
						addEmployee: false,
						id: ''
				},
				input2: '',
				popover:{
					showPopover: false,
					isPopover: true,
					index: '' 
				},
				yiDate: itemData.yi, 
				erData: itemData.er,
				itemData: itemData.arr,
				allKeyCode: {
					 oneSubscript: 0, // 第一级菜单栏 左右上下键盘选中的变量 元素的下标
					 twoSubscript: 0, // 第二级菜单栏 左右上下键盘选中的变量 元素的下标
					 threeSubscript: 0, // 第三级菜单栏 左右上下键盘选中的变量 元素的下标
					 threeNestedSubscript: 0, // 第三级 的 嵌套数组 的下标 
					 agent: 2, // 左右上下键盘 在哪级菜单栏操作  一级0 , 二级1 , 三级2 , 默认选中 2
					 isOneAction: 0, // 第一级菜单栏 选中的样式
					 isTwoAction: 0, // 第二级菜单栏 选中的样式
					 scrollTop_y: 60, // 滚动的距离
					 num: 1, // 滚动的次数
					 total_num: 0, // 滚动的总距离
				}
			}
		},
		mounted() {
			this.addKeyCod()
		},
		/* 
		 *销毁键盘事件  
		 */
		destroyed() {
			document.onkeydown = null
		},
		methods:{
			/* 
			 * 添加键盘事件函数
			 */
			addKeyCod(){
				var that = this
				document.onkeydown = function(event){
					 let key = window.event.keyCode
					 /* enter 按键*/
					 if(key == 13 && that.itemObj.addEmployee == true) that.enterAddEmployee()
					 /* '*'号按键 */
					 if(key == 106) that.enterConfirm()
					 /* 上下左右箭头按键  一级0 , 二级1 , 三级2 */
					 that.allKeyCode.agent == 0 ? that.oneMenuKeyCod(key) : that.allKeyCode.agent == 1 ? that.twoMenuKeyCod(key) : that.threeMenuKeyCod(key)
				}  
			},
			/*
			 * 一级菜单栏的操作
			 */
			oneMenuKeyCod(key){
				if(key == 40){ // 下
				  //调到二级
				  this.allKeyCode.twoSubscript = 0
				  this.allKeyCode.agent = 1
				}else if(key == 39){ // 右
				  this.allKeyCode.oneSubscript = this.allKeyCode.oneSubscript + 1
					if(this.yiDate[this.allKeyCode.oneSubscript] == undefined){
					  this.allKeyCode.oneSubscript = this.allKeyCode.oneSubscript - 1
					}
				}else if(key == 37){
					this.allKeyCode.oneSubscript = this.allKeyCode.oneSubscript - 1
					if(this.yiDate[this.allKeyCode.oneSubscript] == undefined){
					  this.allKeyCode.oneSubscript = this.allKeyCode.oneSubscript + 1
					}
				}
			},
			/*
			 * 二级菜单栏的操作
			 */
			twoMenuKeyCod(key){
				if(key == 38){ //上
					this.allKeyCode.twoSubscript = this.allKeyCode.twoSubscript - 1
					if(this.erData[this.allKeyCode.twoSubscript] == undefined){
						this.allKeyCode.twoSubscript = this.allKeyCode.twoSubscript + 1
						//调到一级
						this.allKeyCode.oneSubscript = 0
						this.allKeyCode.agent = 0
					} 				 
				}else if(key == 40){ // 下
				  this.allKeyCode.twoSubscript = this.allKeyCode.twoSubscript + 1
				  if(this.erData[this.allKeyCode.twoSubscript] == undefined){
				  	this.allKeyCode.twoSubscript = this.allKeyCode.twoSubscript - 1
				  } 
				}else if(key == 39){ // 右
				  this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
				  this.allKeyCode.agent = 2
				}
			},
			/*
			 * 三级菜单栏的操作
			 */
			threeMenuKeyCod(key){
				/* if(key == 38){ //上
					this.allKeyCode.isBox = this.allKeyCode.isBox - 2
					if(this.itemData[this.allKeyCode.isBox] == undefined){
						this.allKeyCode.isBox = this.allKeyCode.isBox + 2
					} 				 
				}else if(key == 40){ // 下
				  this.allKeyCode.isBox = this.allKeyCode.isBox + 2
				  if(this.itemData[this.allKeyCode.isBox] == undefined){
				  	this.allKeyCode.isBox = this.allKeyCode.isBox - 2
				  } 
				}else if(key == 39){ // 右
				  this.allKeyCode.isBox = this.allKeyCode.isBox + 1
					if(this.itemData[this.allKeyCode.isBox] == undefined){
						this.allKeyCode.isBox = this.allKeyCode.isBox - 1
					}
				}else if(key == 37){ // 左
				  // if((num%2)===0){} 
					this.allKeyCode.isBox = this.allKeyCode.isBox - 1
				  if(this.itemData[this.allKeyCode.isBox] == undefined){
					  this.allKeyCode.isBox = this.allKeyCode.isBox + 1
				  } 
				} */
				if(key == 38){ //上
				  this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = false
					this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript - 2
					//滚动运算
					this.$refs.elMain.scrollTop = this.allKeyCode.total_num - this.allKeyCode.scrollTop_y
					this.allKeyCode.total_num = (this.allKeyCode.total_num - this.allKeyCode.scrollTop_y) <= 0 ? 0 : this.allKeyCode.total_num - this.allKeyCode.scrollTop_y 
					this.allKeyCode.num =  (this.allKeyCode.num - 1) <= 0 ? 0 : this.allKeyCode.num - 1
					if(this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript] == undefined){
						if(this.allKeyCode.threeNestedSubscript == 0){
							// 最顶
							this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript + 2
							this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = false
							// 跳到一级
							this.allKeyCode.oneSubscript = this.yiDate.length - 1
							this.allKeyCode.agent = 0
							// 滚动
							this.allKeyCode.total_num = 0
						}else{
							let y = ( (0 < this.allKeyCode.threeNestedSubscript)  && (this.allKeyCode.threeNestedSubscript < this.itemData.length)) ? this.allKeyCode.threeNestedSubscript-1 : 0
							this.allKeyCode.threeNestedSubscript = y
							this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript%2 == 0 ? this.itemData[this.allKeyCode.threeNestedSubscript].itemData.length-1 : this.itemData[this.allKeyCode.threeNestedSubscript].itemData.length-2
							this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
							// 滚动
							this.allKeyCode.num =  (this.allKeyCode.num - 1) <= 0 ? 0 : this.allKeyCode.num - 1
							this.allKeyCode.total_num = (this.allKeyCode.total_num - this.allKeyCode.scrollTop_y) <= 0 ? 0 : this.allKeyCode.total_num - this.allKeyCode.scrollTop_y
						}
					}else{
						this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
					} 				 
				}else if(key == 40){ // 下
					this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = false
				  this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript + 2
					//滚动运算
					this.$refs.elMain.scrollTop = this.allKeyCode.scrollTop_y * this.allKeyCode.num
					this.allKeyCode.total_num = this.allKeyCode.scrollTop_y * this.allKeyCode.num
					let sun = (this.allKeyCode.total_num + this.itemData.length*45) < (this.$refs.elMain.scrollHeight-32)
					this.allKeyCode.num = sun ? this.allKeyCode.num + 1 : this.allKeyCode.num
				  if(this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript] == undefined){
						this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript - 2
						if(this.allKeyCode.threeNestedSubscript == this.itemData.length-1){ //边界问题
							this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
						}else if(this.allKeyCode.threeNestedSubscript < this.itemData.length){
							this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = false
							this.allKeyCode.threeNestedSubscript = this.allKeyCode.threeNestedSubscript + 1
							this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript%2 == 0 ? 0 : 1  //解决：向下不会直接跳转到对应行 0：第一个 1：第二个
							this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
							//滚动
							let sun = (this.allKeyCode.total_num + this.itemData.length*45) < (this.$refs.elMain.scrollHeight-32)
							this.allKeyCode.num = sun ? this.allKeyCode.num + 1 : this.allKeyCode.num
							this.allKeyCode.total_num = this.allKeyCode.scrollTop_y * this.allKeyCode.num
						}
				  }else{
						this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
					} 
				}else if(key == 39){ // 右
				  this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = false
				  this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript + 1
					if(this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript] == undefined){
						this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript - 1
						this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
					}else{
						this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
					}
				}else if(key == 37){ // 左
				  
					// 跳到二级导航
			    if(this.allKeyCode.threeSubscript%2 == 0){ 
					  this.allKeyCode.twoSubscript =  (this.allKeyCode.twoSubscript == (this.erData.length-1)) ? this.erData.length-1 : this.allKeyCode.isTwoAction + 1 
						this.allKeyCode.agent = 1
						this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = false
			    	return
			    }
					this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = false
					this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript - 1
				  if(this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript] == undefined){
					  this.allKeyCode.threeSubscript = this.allKeyCode.threeSubscript + 1
						this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
				  }else{
						this.itemData[this.allKeyCode.threeNestedSubscript].itemData[this.allKeyCode.threeSubscript].isBox = true
					} 
				}
				
			},
			publicMenuKeyCod(){
				
			},
		  //表格
		  enterAddItem($event, index){
		  	let priceRef = this.$refs.price_0
		  	if(priceRef){
		  		priceRef[0].focus()
		  	}
		  },
		  enterPrice($event, index){
		  	this.$refs[`number_${index}`][0].focus()
		  },
		  enterNumber($event,index){
		  	this.$refs[`integral_${index}`][0].focus()
		  },
		  enterIntegral($event,index){
		  	this.itemObj.id = index
		  	this.$refs['integral_'+index][0].blur()
		  	this.$refs[`popover_${index}`][0].showPopper = true
		  	this.$nextTick(function(){
		  		this.$refs['employee_'+0][0].focus()
		  	})
		  	console.log(this.$refs['employee_'+0][0])
		  },
		  //二级
		  enterEmployee(event,data){
		  	this.$refs[`assign_${data}`][0].focus()
		  },
		  enterAssign(event,data){
		  	this.$refs[`perfor_${data}`][0].focus()
		  },
		  enterPerfor(event,data){
		  	this.$refs[`rotay_${data}`][0].focus()
		  },
		  enterRotay(event , index, id){ //id: 行的下标
		  	if(index<(this.itemObj.tableData[id].field6.length-1)){
		  		this.$refs['employee_'+(index+1)][0].focus()
		  	}else{
		  		this.$refs['rotay_'+index][0].blur()
		  		this.itemObj.id = id
		  		this.itemObj.addEmployee = true
		  	}
		  },
		  enterAddEmployee(){
		  	this.itemObj.tableData[this.itemObj.id].field6.push({ 
		  		id: 3 , 
		  		field1: '发型师' , 
		  		field2: ''/* [
		  			{ id: 1 , name: '张三'},
		  			{ id: 2 , name: '李四'},
		  		] */,  
		  		field3: 0,
		  		field4: 0,
		  		field5: 0
		  	}) 
		  	this.itemObj.addEmployee = false
		    var index = this.itemObj.tableData[this.itemObj.id].field6.length-2
		  	this.$nextTick(function(){
		  		this.$refs['rotay_'+index][0].focus()
		  	})
		  },
		  enterConfirm(){
		  	const index = this.itemObj.id
		  	this.$refs[`popover_${index}`][0].showPopper = false
		  },
		  showPopover(index){
		  	this.itemObj.id = index
		  },
		}
	}
</script>

<style lang="less" scoped>
.input{
	margin: 20px 0;
	.all{
		display: flex;
		align-items: center;
		border: 1px solid #c0ccda;
		border-radius: 2px;
		height: 50px;
		i{
			padding: 0 20px;
			font-size: 18px;
		}
	}
}
.table{
	table{
		width: 100%;
	}
	table,table td , table th{
		border: 1px solid #c0ccda;
		border-collapse: collapse;
	}
	table th{
		padding: 10px 20px;
		text-align: left;
		color: #333333;
	}
	table td{
		padding: 20px;
	}
	.input{
		width: 100px;
	}
	.number{
		width: 120px;
	}
	.select{
		width: 240px;
	}
	.employee{
		p{
			line-height: 18px;
			color: #2598ff;
			margin: 0;
		}
		p>span:nth-child(1),
		p>span:nth-child(2){
			margin-right: 5px;
		}
		p>span:nth-child(3){
			color: #333333;
			margin-right: 5px;
		}
	}
	.edit{
		width: 30px;
		text-align: center;
		font-size: 18px;
	}
}
</style>
