<template>
    <div class="order">
      <el-row>
        <el-col :span="2">
					<div>
						<el-button  @click="addTab(editableTabsValue)" icon="el-icon-first-aid-kit"></el-button>
					</div>
				</el-col>
				<el-col :span="20">
					<div class="tabs">
						<el-tabs v-model="editableTabsValue" type="card" @tab-remove="removeTab">
						  <el-tab-pane
							  v-for="(item, index) in editableTabs"
								:key="item.name">
								<span slot="label">
									{{item.title}}
									<i class="el-icon-date"></i>
								</span>
						  </el-tab-pane>
						</el-tabs>
					</div>
				</el-col>
				<el-col :span="2">
					<div class="right">
				    <span>手牌取单 F7</span>
						<span>
							<i class="el-icon-s-grid"></i>
						</span>
					</div>
				</el-col>
      </el-row>
			<div class="box">
				<div class="top" v-show="true">
					<div class="sex">
						<span style="background-color: #20a0ff; color: #FFFFFF; border-right: none;">
							<i class="el-icon-male"></i>
							<em>男</em>
							<i>散客</i>
						</span>
						<span style="background-color: #fb5f8e; color: #FFFFFF; border-left: none;">
							<i class="el-icon-female"></i>
							<em>女</em>
							<i>散客</i>
						</span>
					</div>
					<div class="search">
						<el-input placeholder="请输入内容" v-model="input2">
							<template slot="append">.com</template>
						</el-input>
						<el-button>创建会员</el-button>
					</div>
				</div>
				<div class="member" v-show="false">
					<div>
						<el-popover
							placement="bottom-start"
							width="350"
							trigger="hover">
							  <div class="popover">
									<ul>
										<li>
											<span>25元</span>
											<span>累计消费</span>
										</li>
										<li>
											<span>0元</span>
											<span>累计充值</span>
										</li>
										<li>
											<span>8天前</span>
											<span>上次消费</span>
										</li>
										<li>
											<span>1</span>
											<span>到店次数</span>
										</li>
										<li>
											<span>1</span>
											<span>可用积分</span>
											<div class="button">
												<el-button size="mini" type="primary">赠送</el-button>
												<el-button size="mini">折扣</el-button>
											</div>
										</li>
										<li>
											<span class="red">0</span>
											<span>本店欠款(元)</span>
										</li>
									</ul>
								</div>
							  <div slot="reference" class="reference">
							  	<div class="image">何</div>
							  	<div class="name">
							  		<p>
							  			<span>何晓莉</span>
											<i class="el-icon-female"></i>
							  			<span class="active">活跃</span>
							  		</p>
							  		<p>13420339416</p>
							  		<p>跟踪：江志峰</p>
							  	</div>
							  </div>
						</el-popover>
					</div>
					<div class="list">
						<ul>
							<li>
								<p>卡号：z58904305</p>
								<p>会员卡</p>
								<p>开卡门店：本店</p>
							</li>
							<li>
								<p>储值余额：190元</p>
								<p>欠款：0元</p>
								<p>赠金：48元</p>
							</li>
							<li>
								<p>期限</p>
								<p>无限期</p>
								<p>续约</p>
							</li>
						</ul>
					</div>
					<div></div>
				</div>
				<div class="note" v-show="false">
					<div>
						<span>标签</span>
						<el-select v-model="value1" multiple placeholder="请选择" size="small">
							<el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div>
						<span>备注</span>
						<el-input v-model="input" placeholder="请输入内容" size="small"></el-input>
					</div>
				</div>
				<div class="bottom">
					<el-tabs v-model="editableTabsValue2" type="card" @tab-remove="removeTab">
					  <el-tab-pane
						  v-for="(item, index) in editableTabs2"
							:key="item.name"
							:label="item.title">
					  </el-tab-pane>
					</el-tabs>
				</div>
			</div>
      <Bill></Bill>
		</div>
</template>

<script>
import Bill from './components/bill.vue'
export default {
    components:{ Bill },
    data() {
        return {
          editableTabsValue: '0',
					editableTabs: [{
						title: '16661215454545',
						name: '1',
						content: 'Tab 1 content'
					}, {
						title: '14545415451222',
						name: '2',
						content: 'Tab 2 content'
					}],
					tabIndex: 2 ,
					editableTabsValue2: '0',
					editableTabs2: [{
						title: '开单',
						name: '1',
						content: 'Tab 1 content'
					}, {
						title: '开卡',
						name: '2',
						content: 'Tab 2 content'
					},
					{
						title: '充值',
						name: '3',
						content: 'Tab 2 content'
					},
					{
						title: '疗程/套餐',
						name: '4',
						content: 'Tab 2 content'
					},
					{
						title: '自定义套餐',
						name: '1',
						content: 'Tab 1 content'
					}, {
						title: '还款',
						name: '2',
						content: 'Tab 2 content'
					},
					{
						title: '优惠券',
						name: '3',
						content: 'Tab 2 content'
					},
					{
						title: '卡升级',
						name: '4',
						content: 'Tab 2 content'
					},
					{
						title: '卡金转赠',
						name: '4',
						content: 'Tab 2 content'
					}],
					input:'',
					that: 'oooo',
					visible: false,
        }
    },
    
		
    methods: {
			
      addTab(targetName) {
				let newTabName = ++this.tabIndex + '';
				this.editableTabs.push({
					title: '14545415451222'+ (++this.tabIndex),
					name: newTabName,
					content: 'New Tab content'
				});
				this.editableTabsValue = newTabName;
			},
			removeTab(targetName) {
				let tabs = this.editableTabs;
				let activeName = this.editableTabsValue;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}
				this.editableTabsValue = activeName;
				this.editableTabs = tabs.filter(tab => tab.name !== targetName);
			}, 
			onShowVisible(){
				this.visible = true
			} 
    },
};
</script>

<style lang="less" scoped>
.el-row{
	margin-bottom: 20px;
	.el-col:nth-child(1){
		width: 4%;
	}
	.el-col:nth-child(2){
		width: 87%;
		margin-right: 0.6%;
	}
	.right{
		width: 100%;
		height: 40px;
		line-height: 38px;
		display: flex;
		justify-content: space-between;
		border: 1px solid #E4E7ED !important;
		box-sizing: border-box;
		padding: 0 10px;
		border-radius: 5px;
		color: #20a0ff;
		span:nth-child(2){
			padding-left: 10px;
			border-left: 1px solid #E4E7ED;
		}
	}
}
.box{
	border-bottom: 1px solid #E4E7ED;
	border-top: 1px solid #E4E7ED;
	padding: 20px 0;
	.top{
		display: flex;
		height: 78px;
		.sex{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 220px;
			background-color: #f4f6f8;
			border: 1px solid #c0ccda;
			border-radius: 5px;
			span{
				width: 90px;
				height: 36px;
				line-height: 34px;
				text-align: center;
				display: inline-block;
				border: 1px solid #c0ccda;
				box-sizing: border-box;
				background-color: #FFFFFF;
			}
			span:nth-child(1){
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}
			span:nth-child(2){
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
		.search{
			display: flex;
			align-items: center;
			flex: 1;
			margin-left: 20px;
			background-color: #f4f6f8;
			border: 1px solid #c0ccda;
			border-radius: 5px;
			padding: 0 20px;
			.el-input{
				width: 50%;
			}
			.el-button{
				margin-left: 20px;
			}
		}
	}
	.member{
		display: flex;
		height: 124px;
		padding: 20px;
		box-sizing: border-box;
		border-radius: 5px;
		background-color: #2598ff;
		>div{
			height: 100%;
		}
		>div:nth-child(1){
			flex: 1;
			border-right: 1px solid #efefef;
			.reference{
				display: flex;
				align-items: center;
				line-height: 10px;
			}
			
			.image{
				width: 54px;
				height: 54px;
				background-color: #efefef;
				border-radius: 50%;
				text-align: center;
				line-height: 54px;
				font-size: 24px;
			}
			.name{
				margin-left: 10px;
				color: #FFFFFF;
				i{
					margin-left: 10px;
				}
				.active{
					padding: 2px;
					background-color: #efefef;
					color: #2598ff;
					border-radius: 2px;
					font-size: 10px;
					margin-left: 10px;
				}
			}
		}
		>div:nth-child(2){
			flex: 4;
			color: #FFFFFF;
			ul{
				display: flex;
				list-style: none;
				margin-left: 30px;
				li{
					width: 260px;
				}
				p{
					line-height: 10px;
				}
			}
		}
		>div:nth-child(3){
			flex: 3;
		}
	}
	.note{
		display: flex;
		margin-top: 10px;
		>div{
			display: flex;
			align-items: center;
			flex: 1;
			>span{
				margin-right: 10px;
			}
			.el-select{
				flex: 1;
			}
			.el-input{
				flex: 1;
			}
			.el-input__inner{
				background-color: #eff2f7 !important;
			}
		}
		>div:nth-child(2){
			margin-left: 20px;
		}
	}
	.bottom{
		margin-top: 20px;
	}
	
}

</style>