export default {
	yi:[
		{ id: 1 , title: '服务' , arr: [] },
		{ id: 2 , title: '商品' , arr: [] }
	],
	
	er:[
		{ id: 1 , title: '常用' , arr: [] },
		{ id: 2 , title: '洗吹' , arr: [] },
		{ id: 3 , title: '洗剪吹' , arr: [] },
		{ id: 4 , title: '烫染' , arr: [] },
		{ id: 5 , title: '洗护' , arr: [] },
		{ id: 6 , title: '焗油' , arr: [] },
		{ id: 7 , title: '烫发护理' , arr: [] }
	],
	
	arr: [
		{ id: 1 , 
		  title: '高级烫染' , 
		  itemData:[
		  	{ id: 1 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: true},
		  	{ id: 2 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 3 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 4 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 5 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 6 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 7 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 8 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 9 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  ] 
		},
		{ id: 2 ,
		  title: '资深烫染' , 
		  itemData:[
		  	{ id: 1 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 2 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 3 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 4 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 5 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 6 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 7 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 8 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 9 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  ] 
		},
		{ id: 2 ,
		  title: '资深烫染' , 
		  itemData:[
		  	{ id: 1 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 2 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 3 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 4 , number: '0088' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 5 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 6 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 7 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 8 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  	{ id: 9 , number: '0099' , name: '总监儿童剪发10次卡' , isBox: false},
		  ] 
		}
	]
	
}